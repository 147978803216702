import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
	useRef,
} from "react";
import authService from "../services/auth";
import userService from "../services/user";
import Cookies from "universal-cookie";
import type { ClientUserModel } from "@coffitivity/shared-ts-types";

// Access cookies that are non-HttpOnly
const clientSideCookies = new Cookies(null, { path: "/" });

export interface AuthContextProps {
	accessToken: string;
	isAuthenticated: boolean;
	isSubscribed: boolean;
	user: ClientUserModel;
	fetchUser: (_accessToken: string) => Promise<void>;
	setUser: (user: ClientUserModel) => void;
	login: (email: string, password: string) => Promise<void>;
	logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [accessToken, setAccessToken] = useState<string>("");
	const [user, setUser] = useState<ClientUserModel>({} as ClientUserModel);

	const isSubscribed = user ? user.isSubscribed : false;

	// Ref to track whether session restoration has already been done
	const sessionRestoredRef = useRef(false);

	// Logout logic
	const logout = useCallback(() => {
		setIsAuthenticated(false);
		setUser({} as ClientUserModel);
		// Removes any non-HttpOnly cookies (session presence flag)
		clientSideCookies.remove("isAuthenticated", { path: "/" });
	}, []);

	// Fetch user details after login or token refresh
	const fetchUser = useCallback(
		async (_accessToken: string) => {
			try {
				const [userData, userError] = await userService.getMe(_accessToken);

				if (userError || !userData) {
					return Promise.reject(userError ?? new Error("No user data sent"));
				}

				// Update local state with user data
				setUser(userData);
				setIsAuthenticated(true);
			} catch (error) {
				logout(); // Log out if user fetching fails
			}
		},
		[logout],
	);

	const login = useCallback(
		async (email: string, password: string) => {
			try {
				const [data, loginError] = await authService.signIn(email, password);

				if (loginError) {
					throw loginError;
				}

				const { accessToken } = data!;

				setAccessToken(accessToken);

				// If login is successful, fetch user data
				await fetchUser(accessToken);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		[fetchUser],
	);

	// Restore session or perform token refresh
	const handleSessionRestoration = useCallback(async () => {
		// Ensure session restoration only runs once.
		if (sessionRestoredRef.current) return;

		sessionRestoredRef.current = true; // Mark session restoration as done

		if (clientSideCookies.get("isAuthenticated")) {
			try {
				// Try fetching user data if we know the user is authenticated
				await fetchUser(accessToken);
			} catch (error) {
				if (
					error instanceof Error &&
					error?.message === "You need to provide an access token."
				) {
					// Make a token refresh call if fetching user fails due to missing access token
					const [data, refreshError] = await authService.refresh();

					if (!refreshError) {
						setAccessToken(data!.accessToken);
						await fetchUser(data!.accessToken); // Fetch user after successful refresh
					} else {
						logout();
					}
				}
			}
		} else {
			console.log("No session to restore (isAuthenticated cookie not present)");
		}
	}, [fetchUser, logout, accessToken]);

	// Runs on component mount to restore session or refresh tokens
	useEffect(() => {
		handleSessionRestoration();
	}, [handleSessionRestoration]);

	return (
		<AuthContext.Provider
			value={{
				accessToken,
				fetchUser,
				isAuthenticated,
				isSubscribed,
				login,
				logout,
				setUser,
				user: user as ClientUserModel,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
