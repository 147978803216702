import { useEffect, useState } from "react";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import letterMark from "/letter-mark.png";
import { useAuth } from "../providers/AuthProvider";
import Spinner from "react-bootstrap/esm/Spinner";
import userService from "../services/user";

const CheckoutSuccess: React.FC = () => {
	const { user, setUser, accessToken } = useAuth();
	const navigate = useNavigate();
	const [error, setError] = useState("");

	useEffect(() => {
		const updateTokens = async () => {
			if (user.id) {
				const [success, error] = await userService.updateFromPayment(
					user.id,
					accessToken,
				);

				if (error) {
					setError(
						"There was an error updating your browser. Administration has been notified to resolve this error. You can try logging out and back in, which may help. We'll be in contact with you shortly",
					);
				}

				if (success) {
					setUser(success);
					navigate("/");
				}
			}
		};

		updateTokens();
	});

	return (
		<Container fluid className="h-100">
			<Row className="justify-content-center align-items-center h-100">
				<Col xs={12} md={6} xl={4}>
					<img
						src={letterMark}
						alt="Coffitivity"
						className="d-block mx-auto mb-3"
					/>

					<Card>
						<Card.Header>
							<Card.Title className="d-flex justify-content-between">
								Payment Confirmation
							</Card.Title>
							<Card.Body>
								<div>
									<p>
										{error
											? error
											: "Please wait while we update your account. This should only take a few seconds."}
									</p>
									{!error && (
										<Spinner
											className="d-block mx-auto"
											animation="border"
											variant="primary"
										/>
									)}
								</div>
							</Card.Body>
						</Card.Header>
						<Card.Body />
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default CheckoutSuccess;
