import type {
	ApiRequestResponse,
	ClientUserModel,
} from "@coffitivity/shared-ts-types";
import * as Sentry from "@sentry/react";

export const userService = {
	getMe: async (
		_accessToken: string,
	): Promise<ApiRequestResponse<ClientUserModel>> => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/user/me`,
				{
					method: "GET",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${_accessToken}`,
					},
				},
			);

			const json: { data: ClientUserModel; message: string } =
				await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
	updateFromPayment: async (
		_id: string,
		_accessToken: string,
	): Promise<ApiRequestResponse<ClientUserModel>> => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/user/${_id}/update-from-payment`,
				{
					method: "GET",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${_accessToken}`,
					},
				},
			);

			const json: { data: ClientUserModel; message: string } =
				await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
};

export default userService;
